<!-- Button -->
<button *ngIf="isAuthenticated" mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        @if (showAvatar && user && user.avatar) {
            <img class="h-7 w-7 rounded-full" [src]="user.avatar" />
        }
        @if (!showAvatar || !user || !user.avatar) {
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        }
        <span
            class="absolute bottom-0 right-0 h-2 w-2 rounded-full"
            [ngClass]="{
                'mb-px mr-px': !showAvatar || !user || !user.avatar,
                'bg-green-500': user && user.status === 'online',
                'bg-amber-500': user && user.status === 'away',
                'bg-red-500': user && user.status === 'busy',
                'bg-gray-400': user && user.status === 'not-visible',
            }"
        ></span>
    </span>
</button>
<ng-container *transloco="let t">
<button *ngIf="!isAuthenticated" mat-mini-fab [routerLink]="'/sign-in'" [queryParams]="{lang:activeLanguage}" class="bg-primary mat-elevation-z0" [matTooltip]="t('Sign In')">
    <span class="relative">
        <mat-icon [svgIcon]="'mat_outline:login'"></mat-icon>
    </span>
</button>
</ng-container>


<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <ng-container *transloco="let t">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{t('Signed in as')}}</span>
            <span class="mt-1.5 text-md font-medium">{{ user ? user.email : '' }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item [routerLink]="['/profile']">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{ t('Profile')}} </span>
    </button>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>{{ t('Settings')}} </span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="userStatus">
        <mat-icon
            [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"
        ></mat-icon>
        <span>{{t('Status')}}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon
            [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
        ></mat-icon>
        <span>{{t('Sign out')}}</span>
    </button>
    </ng-container>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
    <ng-container *transloco="let t">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-green-500"></span>
        <span>{{t('Online')}}</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-amber-500"></span>
        <span>{{t('Away')}}</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-red-500"></span>
        <span>{{t('Busy')}}</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-gray-400"></span>
        <span>{{t('Invisible')}}</span>
    </button>
</ng-container>
</mat-menu>