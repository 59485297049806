import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseEntityService, CrudEndpoints } from '@gci/components/base-page/base-entity.service';
import { EntityTransformFn } from '@gci/components/base-page/base-parent-child.service';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs';

export interface NotificationData {
    id: string
    subscription: PushSubscriptionJSON;
    meta_data: any;
}

@Injectable({providedIn: 'root'})
export class PushNotificationService extends BaseEntityService<NotificationData> {

    private _notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);

    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    constructor(
        httpClient: HttpClient
    ) {
        const endpoints: CrudEndpoints = {
            create: 'notification/subscription',
            delete: (id: string) => `notification/subscription/${id}`,
            get_notif_history: `notification/history`,
            update_notif_history: (id: string) => `notification/history/${id}`,
            count_notif_history: `notification/history/count`,
        };
        super(httpClient, environment.apiBaseUrl, endpoints, 'GroupService')
    }
    
    registerSubscription(subscription: PushSubscriptionJSON, metadata: any): Observable<NotificationData> {
        let request = {
            subscription: subscription,
            "meta_data": metadata
        }
        this.logger.info('regissubscribe', request);
        return this.create(request);
    }

    deleteSubscribtion(sub_id: string): Observable<NotificationData> {
        return this.delete(sub_id);
    }

    getNotificationHistory(user_id?: string, device_id?: string, read?: boolean, filter?: string, page?: number, limit?: number) {
        const queryParams: Record<string, string> = {
            user_id: user_id ?? '',
            device_id: device_id ?? '',
            filter: filter ?? '',
            page: `${page ?? 1}`,
            limit: `${limit ?? 10}`,
            read: `${read !== undefined ? read : ''}`
        };

        const url = `${this.baseUrl}${this.endpoints['get_notif_history']}${this.buildQueryString(queryParams)}`;
        
        return this.httpClient.get<{message: {data: Notification[], pagination: any}}>(url).pipe(
            map(res => res.message),
            tap(res => {
                // console.log("Notifs: ", res)
                const {data, pagination} = res;
                this._notifications.next(data);
            }),
            catchError(err => {
                return this.handleOperationError('getNotificationHistory', this.endpoints['get_notif_history'], err);
            })
        )
    }

    updateNotificationHistory(notif_id: string, body: Partial<Notification>, transformFn?: EntityTransformFn<Notification> | undefined) {
        const url = `${this.baseUrl}${this.endpoints['update_notif_history'](notif_id)}`;

        return this.httpClient.put<{message: any}>(url, body).pipe(
            map(res => res.message.data),
            tap(res => {
                const notifications = this._notifications.getValue();

                const entityIndex = notifications.findIndex(el => el.id === res.id);
                if(entityIndex !== -1) {
                    const updatedEntity = transformFn 
                    ? transformFn(notifications[entityIndex], res)
                    : res;

                    notifications[entityIndex] = updatedEntity;

                    this._notifications.next(notifications);
                }
            }),
            catchError(err => {
                return this.handleOperationError('updateNotificationHistory', this.endpoints['update_notif_history'](notif_id), err);
            })
        )
    }

    countNotificationHistory(device_id: string) {
        const queryParams: Record<string, string> = {device_id};

        const url = `${this.baseUrl}${this.endpoints['count_notif_history']}${this.buildQueryString(queryParams)}`;

        return this.httpClient.get<{message: any}>(url).pipe(
            map(res => res.message),
            catchError(err => {
                return this.handleOperationError('countNotificationHistory', this.endpoints['count_notif_history'], err);
            })
        )
    }
}