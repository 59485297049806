import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
    APP_INITIALIZER,
    EnvironmentProviders,
    Provider,
} from '@angular/core';
import {
    GCI_WEB_API_DEFAULT_DELAY,
    webApiInterceptor,
} from '@gci/lib/web-api';

export type GciProviderConfig = {
    webApi?: {
        delay?: number;
        services?: any[];
    };
};

/**
 * GCI provider
 */
export const provideGci = (
    config: GciProviderConfig
): Array<Provider | EnvironmentProviders> => {
    // Base providers
    const providers: Array<Provider | EnvironmentProviders> = [
        {
            provide: GCI_WEB_API_DEFAULT_DELAY,
            useValue: config?.webApi?.delay ?? 0,
        },
    ];

    // Web Api services
    if (config?.webApi?.services) {
        providers.push(
            provideHttpClient(withInterceptors([webApiInterceptor])),
            {
                provide: APP_INITIALIZER,
                deps: [...config.webApi.services],
                useFactory: () => (): any => null,
                multi: true,
            }
        );
    }

    // Return the providers
    return providers;
};
