<footer id="footer" class="bg-gray-300 lg:px-32">
    <div class="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
        <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div>
                <div class="">
                    <img [src]="" alt="Insert logo here later" class="h-24">
                </div>
        
                <p class="mt-4 max-w-xs text-gray-500">
                    EGate adalah Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse non cupiditate quae nam
                    molestias.
                </p>
        
                <ul class="mt-8 flex gap-6">

                    <!-- <li>
                        <a
                            href="#"
                            rel="noreferrer"
                            target="_blank"
                            class="text-gray-700 transition hover:opacity-75"
                        >
                            <span class="sr-only">Check Circle</span>
                            <mat-icon [svgIcon]="'mat_solid:check_circle'"></mat-icon>
                        </a>
                    </li> -->

                    <li>
                        <a
                            href="#"
                            rel="noreferrer"
                            target="_blank"
                            class="text-gray-700 transition hover:opacity-75"
                        >
                            <span class="sr-only">Instagram</span>
            
                            <svg class="size-6 hover:fill-pink-500" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path
                                    fill-rule="evenodd"
                                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </a>
                    </li>
                    
                    <li>
                        <a
                            href="#"
                            rel="noreferrer"
                            target="_blank"
                            class="text-gray-700 transition hover:opacity-75"
                        >
                            <span class="sr-only">Facebook</span>
            
                            <svg class="size-6 hover:fill-blue-500" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path
                                    fill-rule="evenodd"
                                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </a>
                    </li>
        
                    <li>
                        <a
                            href="#"
                            rel="noreferrer"
                            target="_blank"
                            class="text-gray-700 transition hover:opacity-75"
                        >
                            <span class="sr-only">Whatsapp</span>

                            <svg class="size-6 hover:fill-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z" clip-rule="evenodd"/>
                                <path fill-rule="evenodd" d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z"/>
                            </svg>
                        </a>
                    </li>
        
                    <li>
                        <a
                            href="#"
                            rel="noreferrer"
                            target="_blank"
                            class="text-gray-700 transition hover:opacity-75"
                        >
                            <span class="sr-only">YouTube</span>
            
                            <svg class="size-6 hover:fill-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z" clip-rule="evenodd"/>
                            </svg>
                            
                        </a>
                    </li>
        
                </ul>
            </div>
    
            <div class="grid grid-cols-2 gap-8 lg:col-span-2 lg:grid-cols-4">

                <div>
                    <p class="font-medium text-gray-900">Solution</p>
        
                    <ul class="mt-6 space-y-4 text-sm">
                        <li>
                            <a href="/solution/pju" class="text-gray-700 transition hover:opacity-75"> PJU </a>
                        </li>
            
                        <li>
                            <a href="/solution/tower" class="text-gray-700 transition hover:opacity-75"> Tower </a>
                        </li>
            
                        <li>
                            <a href="/solution/homecharging" class="text-gray-700 transition hover:opacity-75"> Home Charging </a>
                        </li>
                    </ul>

                    <p class="font-medium text-gray-900 mt-8">Product</p>
        
                    <ul class="mt-6 space-y-4 text-sm">
                        <li>
                            <a href="/product/egate" class="text-gray-700 transition hover:opacity-75"> EGate </a>
                        </li>
            
                        <li>
                            <a href="/product/evgate" class="text-gray-700 transition hover:opacity-75"> EVGate </a>
                        </li>
            
                        <li>
                            <a href="/product/teletoken" class="text-gray-700 transition hover:opacity-75"> Teletoken </a>
                        </li>
                    </ul>
                </div>
        
                <div>

                    <p class="font-medium text-gray-900">Contact</p>
        
                    <ul class="mt-6 space-y-4 text-sm">
                        <li>
                            <a href="#" class="text-gray-700 transition hover:opacity-75"> Contact </a>
                        </li>
            
                        <li>
                            <a href="#" class="text-gray-700 transition hover:opacity-75"> FAQs </a>
                        </li>
            
                        <li>
                            <a href="#" class="text-gray-700 transition hover:opacity-75"> Live Chat </a>
                        </li>
                    </ul>

                    <p class="font-medium text-gray-900 mt-8">Legal</p>
        
                    <ul class="mt-6 space-y-4 text-sm">
                        <li>
                            <a href="#" class="text-gray-700 transition hover:opacity-75"> Accessibility </a>
                        </li>
            
                        <li>
                            <a href="#" class="text-gray-700 transition hover:opacity-75"> Returns Policy </a>
                        </li>
            
                        <li>
                            <a href="#" class="text-gray-700 transition hover:opacity-75"> Refund Policy </a>
                        </li>
            
                        <li>
                            <a href="#" class="text-gray-700 transition hover:opacity-75"> Hiring Statistics </a>
                        </li>
                    </ul>
                </div>

                <div class="overflow-hidden rounded-lg lg:col-span-2 h-96 lg:h-auto hidden lg:block">
                    <iframe allow="fullscreen" allowfullscreen="true" width="100%" height="100%" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1983.1285657334893!2d106.65806433875824!3d-6.229793098439583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb89d14b23f9%3A0x4d949500a2a29b92!2sPT.%20Harapan%20Energie%20Indonesia!5e0!3m2!1sen!2sid!4v1728881867628!5m2!1sen!2sid"></iframe>
                </div>
            </div>
            
            <div class="overflow-hidden rounded-lg lg:col-span-2 h-96 lg:h-auto lg:hidden">
                <iframe allow="fullscreen" allowfullscreen="true" width="100%" height="100%" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1983.1285657334893!2d106.65806433875824!3d-6.229793098439583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb89d14b23f9%3A0x4d949500a2a29b92!2sPT.%20Harapan%20Energie%20Indonesia!5e0!3m2!1sen!2sid!4v1728881867628!5m2!1sen!2sid"></iframe>
            </div>
        </div>
    
        <p class="text-xs text-gray-500">Copyright &copy; {{currentYear}}. EGate. All rights reserved.</p>
    </div>
</footer>
