/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { productData, solutionData } from 'app/models/dummy.constants';

function getProductNavigation(): FuseNavigationItem[] {
    let result: FuseNavigationItem[] = []
    for (let product of productData) {
        let { name, key, icon } = product
        result.push(
            {
                id: `products.${key}`,
                title: name,
                type: 'basic',
                icon: icon,
                link: `product/${key}`,
            },
        )
    }
    
    return result
}

function getSolutionNavigation(): FuseNavigationItem[] {
    let result: FuseNavigationItem[] = []
    for (let product of solutionData) {
        let { name, key, icon } = product
        key !== 'others' && result.push(
            {
                id: `solution.${key}`,
                title: name,
                type: 'basic',
                icon: icon,
                link: `solution/${key}`,
            },
        )
    }
    
    return result
}

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'homes',
        title: 'Home',
        type: 'group',
        icon: 'heroicons_solid:home',
        children: [
            {
                id: 'homes.overview',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:home',
                link: 'home'
            }
        ]
    },
    {
        id: 'products',
        title: 'Product',
        type: 'group',
        icon: 'heroicons_solid:cpu-chip',
        link: 'product',
        children: getProductNavigation()
    },
    {
        id: 'solutions',
        title: 'Solutions',
        type: 'group',
        icon: 'heroicons_solid:calculator',
        link: 'solution',
        children: getSolutionNavigation()
        //     [
        //     {
        //         id: 'solutions.pju',
        //         title: 'PJU',
        //         type: 'basic',
        //         icon: 'heroicons_solid:light-bulb',
        //         link: 'solution/pju',
        //     },
        //     {
        //         id: 'solutions.tower',
        //         title: 'Tower',
        //         type: 'basic',
        //         icon: 'heroicons_solid:building-office',
        //         link: 'solution/tower',
        //     },
        //     {
        //         id: 'solutions.homecharging',
        //         title: 'Home Charging',
        //         type: 'basic',
        //         icon: 'mat_solid:ev_station',
        //         link: 'solution/homecharging',
        //     },
        // ],
    },
    {
        id: 'spacer',
        title: 'spacer',
        type: 'spacer',
    },
    // {
    //     id: 'doct',
    //     title: 'Documentation',
    //     type: 'basic',
    //     icon: 'heroicons_solid:document-text',
    //     externalLink: true,
    //     link: 'https://google.com'
    // },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'homes',
        title: 'Home',
        type: 'group',
        icon: 'heroicons_solid:home',
        children: []
    },
    {
        id: 'products',
        title: 'Product',
        type: 'group',
        icon: 'heroicons_solid:cpu-chip',
        link: 'product',
        children: [],
    },
    {
        id: 'solutions',
        title: 'Solutions',
        type: 'group',
        icon: 'heroicons_solid:calculator',
        link: 'solution',
        children: [],
    },
    {
        id: 'spacer',
        title: 'spacer',
        type: 'spacer',
    },
    // {
    //     id: 'doct',
    //     title: 'Documentation',
    //     type: 'basic',
    //     icon: 'heroicons_solid:document-text',
    //     externalLink: true,
    //     link: 'https://google.com'
    // }, 
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'homes',
        title: 'Home',
        type: 'group',
        icon: 'heroicons_solid:home',
        children: []
    },
    {
        id: 'products',
        title: 'Product',
        type: 'group',
        icon: 'heroicons_solid:cpu-chip',
        link: 'product',
        children: [],
    },
    {
        id: 'solutions',
        title: 'Solutions',
        type: 'group',
        icon: 'heroicons_solid:calculator',
        link: 'solution',
        children: [],
    },
    {
        id: 'spacer',
        title: 'spacer',
        type: 'spacer',
    },
    // {
    //     id: 'doct',
    //     title: 'Documentation',
    //     type: 'basic',
    //     icon: 'heroicons_solid:document-text',
    //     externalLink: true,
    //     link: 'https://google.com'
    // }, 
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'homes',
        title: 'Home',
        type: 'group',
        icon: 'heroicons_solid:home',
        children: []
    },
    {
        id: 'products',
        title: 'Product',
        type: 'group',
        icon: 'heroicons_solid:cpu-chip',
        link: 'product',
        children: [],
    },
    {
        id: 'solutions',
        title: 'Solutions',
        type: 'group',
        icon: 'heroicons_solid:calculator',
        link: 'solution',
        children: [],
    },
    // {
    //     id: 'spacer',
    //     title: 'spacer',
    //     type: 'spacer',
    // },
    // {
    //     id: 'doct',
    //     title: 'Documentation',
    //     type: 'basic',
    //     icon: 'heroicons_solid:document-text',
    //     externalLink: true,
    //     link: 'https://google.com'
    // },    
];
