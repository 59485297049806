export interface IToast {
    id: string;
    active: boolean;
    activeTime: number;
    msg: string;
    type: ToastType;
    onHover?: boolean;
    intervalId?: any;  // menyimpan interval
}

export type ToastPosition =
    'tr' |  //top right
    'tm' |  //top midle
    'tl' |  //top left
    'mr' |  //midle right
    'mm' |  //midle midle
    'ml' |  //midle left
    'br' |  //bottom right
    'bm' |  //bottom midle
    'bl'    //bottom left

export type ToastType =
    'error' |
    'warning' |
    'success' |
    'info' |
    'cute'

export enum StyleToastPosition {
    'tr' = 'top-0 right-0',                                       // top right
    'tm' = 'top-0 left-1/2 -translate-x-1/2',                     // top middle
    'tl' = 'top-0 left-0',                                        // top left
    'mr' = 'top-1/2 right-0 -translate-y-1/2',                    // middle right
    'mm' = 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',  // middle middle
    'ml' = 'top-1/2 left-0 -translate-y-1/2',                     // middle left
    'br' = 'bottom-0 right-0',                                    // bottom right
    'bm' = 'bottom-0 left-1/2 -translate-x-1/2',                  // bottom middle
    'bl' = 'bottom-0 left-0',                                     // bottom left
}

export enum StyleToastColor {
    'error' = 'bg-gradient-to-tr from-red-500 to-red-600 text-white',                                      
    'info'  = 'bg-gradient-to-tr from-blue-400 to-blue-500 text-white',                     
    'success' = 'bg-gradient-to-tr from-green-500 to-green-400 text-white',                                        
    'warning' = 'bg-gradient-to-tr from-yellow-500 to-yellow-400 text-white', 
    'cute' = 'bg-gradient-to-tr from-pink-600 to-pink-500 text-white'                                               
}

export enum StyleToastLoadingBarColor {
    'error' = 'bg-red-300 text-white',                                      
    'info'  = 'bg-blue-200 text-white',                     
    'success' = 'bg-green-300 text-white',                                        
    'warning' = 'bg-yellow-300 text-white', 
    'cute' = 'bg-pink-200 text-white'                                               
}