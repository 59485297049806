export const AVAILABLE_LANGUAGES = [
    {
        id: 'en',
        label: 'English',
    },
    {
        id: 'id',
        label: 'Indonesia',
    },
];

export const DEFAULT_LANGUAGE = 'en';
export const FALLBACK_LANGUAGE = 'en';

export const FLAG_CODES = {
    en: 'us',
    id: 'id',
    tr: 'tr'
};

