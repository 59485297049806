/* eslint-disable */
export const shortcuts = [
    {
        id: '989ce876-c177-4d71-a749-1953c477f825',
        label: 'Documentation',
        description: 'Getting started',
        icon: 'heroicons_outline:book-open',
        link: '/docs',
        useRouter: true,
    },
    {
        id: '2496f42e-2f25-4e34-83d5-3ff9568fd984',
        label: 'Help center',
        description: 'FAQs and guides',
        icon: 'heroicons_outline:information-circle',
        link: '/help-center',
        useRouter: true,
    },
    {
        id: '3c48e75e-2ae7-4b73-938a-12dc655be28b',
        label: 'Dashboard',
        description: 'Overview',
        icon: 'heroicons_outline:chart-pie',
        link: '/overview',
        useRouter: true,
    },
    {
        id: '0a240ab8-e19d-4503-bf68-20013030d526',
        label: 'Reload',
        description: 'Reload the app',
        icon: 'heroicons_outline:arrow-path',
        link: '/overview',
        useRouter: false,
    },
];
