import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Logger } from '@gci/helpers/logger';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { of, switchMap } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const host = window.location.host;
    const logger = new Logger('AuthGuard');

    const language = route.queryParamMap.get("lang");
    let langParam = '';
    if (language) {
        langParam = `&lang=${language}`;
    }

    if (route.routeConfig?.canActivateChild && !host.startsWith('localhost')) {
        const hostArr = host.split('.');
        const subdomain = hostArr.length > 2 ? hostArr[0] : '';
        //const domain = hostArr.slice(1).join('.');
    
        logger.debug("host", host, "subdomain", subdomain);

        if (subdomain !== environment.consolePageSubdomain) {
            if (subdomain=='' || subdomain==environment.landingPageSubdomain) {
                logger.debug("navigate to console", subdomain);
                window.location.href = environment.consoleDashboardUrl;
                window.location.href = `${environment.consoleDashboardUrl}?${langParam}`;
            } else {
                logger.log("navigate to console unknown subdomain");
                window.location.href = `${environment.consoleDashboardUrl}?${langParam}`;
            }
            return false;
        }
    }    

    // Check the authentication status
    return inject(AuthService)
        .check()
        .pipe(
            switchMap((authenticated) => {
                // If the user is not authenticated...
                if (!authenticated) {
                    // Redirect to the sign-in page with a redirectUrl param
                    // If the state.url is sign-out, the redirectURL is set to empty
                    const redirectURL =
                        state.url === '/sign-out'
                            ? ''
                            : `redirectURL=${state.url}`;
                    
                    const urlTree = router.parseUrl(`sign-in?${redirectURL}${langParam}`);

                    logger.log("state.url", state.url, "urlTree", urlTree);

                    return of(urlTree);
                }

                // Allow the access
                return of(true);
            })
        );
};
