import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseEntityService, CrudEndpoints } from '@gci/components/base-page/base-entity.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

export interface NotificationData {
    id: string
    subscription: PushSubscriptionJSON;
    meta_data: any;
}

@Injectable({providedIn: 'root'})
export class PushNotificationService extends BaseEntityService<NotificationData> {
    constructor(
        httpClient: HttpClient
    ) {
        const endpoints: CrudEndpoints = {
            create: 'notification/subscription',
            delete: (id: string) => `/notification/subscription/${id}`
        };
        super(httpClient, environment.apiBaseUrl, endpoints, 'GroupService')
    }
    
    registerSubscription(subscription: PushSubscriptionJSON, metadata: any): Observable<NotificationData> {
        let request = {
            subscription: subscription,
            "meta_data": metadata
        }
        return this.create(request);
    }

    deleteSubscribtion(sub: string): Observable<NotificationData> {
        return this.delete(sub);
    }
}