import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { BaseComponent } from '@gci/components/base-page/base-component';
import { Logger } from '@gci/helpers/logger';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { forkJoin, Subject, take, takeUntil } from 'rxjs';
import { SwPush } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { PushNotificationService } from 'app/web-api/common/push-notification.service';
import { ToastService } from '@gci/components/toast/toast.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        NgClass,
        MatDividerModule,
        MatTooltipModule,
        CommonModule,
        RouterModule,
        TranslocoModule
    ],
})
export class UserComponent extends BaseComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    public user!: User;
    public subscription_id!: string;
    public isAuthenticated: boolean = false;
    public isNotificationActive: boolean = false;
    public isNotificationAllowed!: boolean; // Check if notification is allowed in the browser
    public isNotificationSupported!: boolean; // Check if notification is supported by the browser

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        public swPush: SwPush,
        private _userService: UserService,
        private _pushNotificationService: PushNotificationService,
        private _authService: AuthService,
        private _toastService: ToastService,
        private _translocoService: TranslocoService,
    ) {
        super('UserComponent', _translocoService);
        this.swPush.notificationClicks.subscribe(({ action, notification }) => {
            console.log('action', action, 'notification', notification);
        });

        this.swPush.messages.subscribe((payload) => {
            console.log('message payload', payload);
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    override ngOnInit(): void {
        this.activeLanguage = this._translocoService.getActiveLang();

        this.isNotificationAllowed = Notification.permission === 'granted';
        this.isNotificationSupported = "Notification" in window;

        // this._userService.get().subscribe()

        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                if(this.user.subscription_id) this.subscription_id = this.user.subscription_id;

                // Subscription to check if notif subscription is active or not
                this.swPush.subscription.subscribe(sub => {
                    // console.log("Notif Sub: ", sub)
                    if(sub) {
                        this.isNotificationActive = true;
                    }else {
                        this.isNotificationActive = false;
                    }
                })

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to authentication changes
        this._authService.authenticatedSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe({
                next: (val) => {
                    this.logger.debug("authenticationSubject changes", val);
                    this.isAuthenticated = val;

                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                }
            });

        super.ngOnInit();
    }

    get IsSuperAdmin(): boolean { 
        return this._authService?.currentUser?.tenant_code_access === environment.idSuperAdmin;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }
        this.logger.log('User Component', this.user)
        // Update the user
        this._userService
            .update({
                ...this.user,
                status,
            })
            .pipe(take(1))
            .subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Notification
    // -----------------------------------------------------------------------------------------------------

    enableNotifications(): void {
        this.swPush.requestSubscription({
            serverPublicKey: environment.vapidPublicKey
        })
            .then(sub => {
                this._pushNotificationService.registerSubscription(sub, { user_id: this.user.id }).subscribe({
                    next: res => {
                        // console.log(this.user)
                        this.subscription_id = res.id;
                        this._toastService.success("Success Subscribe Notification");
                    }, 
                    error: err => {
                        console.error(err);
                        this._toastService.error("Error Subscribe Notification");
                    }
                })
            })
            .catch(err => {
                this._toastService.error("Error Subscribe Notification");
                this.logger.error("Could not subscribe to notifications", err);
            });
    }

    disableNotifications(): void {
        this.swPush.unsubscribe().then(() => {
            this._pushNotificationService.deleteSubscribtion(this.subscription_id).subscribe(
                () => {
                    this.subscription_id = '';
                    this._toastService.success("Success Unsubscribe Notification");
                }
            )
        })
            .catch((err) => {
                this._toastService.success("Error Unsubscribe Notification");
                console.error("Error unsubscribe notification", err);
                this._toastService.error('Unsubscribe error');
            })

    }
}

