import { Component, Input, OnInit } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { BaseComponent } from '@gci/components/base-page/base-component';
import { SharedModule } from 'app/modules/admin/shared.module';
import { defaultNavigation } from 'app/web-api/landing/navigation/data';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [
        SharedModule
    ],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent extends BaseComponent {
    @Input({required: true}) currentYear!: number;
    public item = defaultNavigation;
    public products: FuseNavigationItem;
    public solutions: FuseNavigationItem;

    constructor() {
        super("Footer");
        this.products = this.item.filter(el => el.id === 'products')[0];
        this.solutions = this.item.filter(el => el.id === 'solutions')[0];
    }

}
