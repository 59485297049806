import { AuthWebApi } from './common/auth.api';
import { NavigationWebApi } from './common/navigation/api';
import { NotificationsWebApi } from './common/notifications/api';
import { SearchWebApi } from './common/search/api';
import { ShortcutsWebApi } from './common/shortcuts/api';
import { LandingNavigationWebApi } from './landing/navigation/api';

export const webApiServices = [
    AuthWebApi,
    ShortcutsWebApi,
    NotificationsWebApi,
    LandingNavigationWebApi,
    NavigationWebApi,
    SearchWebApi,
];

// Explicit export statement
export {};