import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Logger } from '@gci/helpers/logger';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HostRedirectGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const host = window.location.host;
    const logger = new Logger('HostRedirectGuard');
    
    if (!host.startsWith('localhost')) {
      const hostArr = host.split('.');
      const subdomain = hostArr.length > 2 ? hostArr[0] : '';
  
      logger.debug("host", host, "subdomain", subdomain);
  
      if (subdomain=='' || subdomain==environment.landingPageSubdomain) {
        logger.debug("navigate to home");
        this.router.navigate(['/home']);
      } else if (subdomain === environment.consolePageSubdomain) {
        logger.debug("navigate to overview");
        this.router.navigate(['/overview']);
      } else {
        logger.debug("unknown subdomain redirect to landing page");
        this.router.navigate(['/home']);
      }
    } else {
      this.router.navigate(['/home']);
    }

    return true;
  }
}