import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-vertical',
  templateUrl: './footer-vertical.component.html',
  styleUrls: ['./footer-vertical.component.scss']
})
export class FooterVerticalComponent {

}
