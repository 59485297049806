import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterVerticalComponent } from './footer-vertical.component';



@NgModule({
  declarations: [FooterVerticalComponent],
  exports: [FooterVerticalComponent],
  imports: [
    CommonModule
  ]
})
export class FooterVerticalModule { }
