import { Route } from '@angular/router';
import { initialDataResolver, landingInitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { HostRedirectGuard } from './core/auth/guards/host.guard';

import { Component } from '@angular/core';

@Component({
  selector: 'app-dummy',
  template: `<p>Redirecting...</p>`
})
export class DummyComponent {}

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/home'
    {
        path: '', 
        canActivate: [HostRedirectGuard],
        component: DummyComponent
    },

    // Redirect signed-in user to the '/overview'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'overview'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'force-reset-password', loadChildren: () => import('app/modules/auth/force-reset-password/force-reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')},
            {path: 'sign-up-confirmation', loadChildren: () => import('app/modules/auth/sign-up-confirmation/sign-up-confirmation.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve: {
            initialData: landingInitialDataResolver
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/new-home/new-home.routes') },
            { path: 'product/:product', loadChildren: () => import('app/modules/landing/product-page/product-page.routes') },
            { path: 'solution/:solution', loadChildren: () => import('app/modules/landing/solution-page/solution-page.routes') },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'dense'
        },
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'overview', loadChildren: () => import('app/modules/admin/overview-page/overview-page.routes')},
            { path: 'devices', loadChildren: () => import('app/modules/admin/devices-page/devices-page.routes') },
            { path: 'alertrules', loadChildren: () => import('app/modules/admin/alert-rules-page/alert-rules-page.routes')},
            { path: 'members', loadChildren: () => import('app/modules/admin/members-page/members-page.routes') },
            { path: 'devices/:group_id/:id', loadChildren: () => import('app/modules/admin/devices-detail/devices-detail.routes') },
            { path: 'profile', loadChildren: () => import('app/modules/user-profile/user-profile.routes') },
        ]
    }
];
