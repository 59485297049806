/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'main',
        title: 'Main',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:computer-desktop',
                link: 'overview',
            },
            {
                id: 'devices',
                title: 'Devices',
                type: 'basic',
                icon: 'heroicons_outline:device-mobile',
                link: 'devices',
            },
            {
                id: 'alertrules',
                title: 'Alert Rules',
                type: 'basic',
                icon: 'heroicons_outline:bell',
                link: 'alertrules',
            },
            {
                id: 'members',
                title: 'Members',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'members',
            }
        ],
    },
    {
        id: 'spacer',
        type: 'spacer'
    },
    // {
    //     id: 'doct',
    //     title: 'Documentation',
    //     type: 'basic',
    //     icon: 'heroicons_outline:document-text',
    //     externalLink: true,
    //     link: 'sign-out'
    // },
    {
        id: 'exit',
        title: 'Exit',
        type: 'group',
        children: [
            {
                id: 'signout',
                title: 'Sign out',
                type: 'basic',
                icon: 'heroicons_outline:arrow-left-on-rectangle',
                link: 'sign-out'
            },
        ]
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'main',
        title: 'Main',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:computer-desktop',
                link: 'overview',
            },
            {
                id: 'devices',
                title: 'Devices',
                type: 'basic',
                icon: 'heroicons_outline:device-mobile',
                link: 'devices',
            },
            {
                id: 'alertrules',
                title: 'Alert Rules',
                type: 'basic',
                icon: 'heroicons_outline:bell',
                link: 'alertrules',
            },
            {
                id: 'members',
                title: 'Members',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'users',
            }
        ],
    },
    {
        id: 'spacer',
        type: 'spacer'
    },
    // {
    //     id: 'doct',
    //     title: 'Documentation',
    //     type: 'basic',
    //     icon: 'heroicons_outline:document-text',
    //     externalLink: true,
    //     link: 'sign-out'
    // },
    {
        id: 'exit',
        title: 'Exit',
        type: 'group',
        children: [
            {
                id: 'signout',
                title: 'Sign out',
                type: 'basic',
                icon: 'heroicons_outline:arrow-left-on-rectangle',
                link: 'sign-out'
            },
        ]
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'main',
        title: 'Main',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:computer-desktop',
                link: 'overview',
            },
            {
                id: 'devices',
                title: 'Devices',
                type: 'basic',
                icon: 'heroicons_outline:device-mobile',
                link: 'devices',
            },
            {
                id: 'alertrules',
                title: 'Alert Rules',
                type: 'basic',
                icon: 'heroicons_outline:bell',
                link: 'alertrules',
            },
            {
                id: 'members',
                title: 'Members',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'members',
            }
        ],
    },
    {
        id: 'spacer',
        type: 'spacer'
    },
    // {
    //     id: 'doct',
    //     title: 'Documentation',
    //     type: 'basic',
    //     icon: 'heroicons_outline:document-text',
    //     externalLink: true,
    //     link: 'sign-out'
    // },
    {
        id: 'exit',
        title: 'Exit',
        type: 'group',
        children: [
            {
                id: 'signout',
                title: 'Sign out',
                type: 'basic',
                icon: 'heroicons_outline:arrow-left-on-rectangle',
                link: 'sign-out'
            },
        ]
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'main',
        title: 'Main',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:computer-desktop',
                link: 'overview',
            },
            {
                id: 'devices',
                title: 'Devices',
                type: 'basic',
                icon: 'heroicons_outline:device-mobile',
                link: 'devices',
            },
            {
                id: 'alertrules',
                title: 'Alert Rules',
                type: 'basic',
                icon: 'heroicons_outline:bell',
                link: 'alertrules',
            },
            {
                id: 'members',
                title: 'Members',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'members',
            }
        ],
    },
    {
        id: 'spacer',
        type: 'spacer'
    },
    // {
    //     id: 'doct',
    //     title: 'Documentation',
    //     type: 'basic',
    //     icon: 'heroicons_outline:document-text',
    //     externalLink: true,
    //     link: 'sign-out'
    // },
    {
        id: 'exit',
        title: 'Exit',
        type: 'group',
        children: [
            {
                id: 'signout',
                title: 'Sign out',
                type: 'basic',
                icon: 'heroicons_outline:arrow-left-on-rectangle',
                link: 'sign-out'
            },
        ]
    },
];
