/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'stroomer',
        title: 'Stroomer',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:computer-desktop',
                link: 'overview',
            },
            {
                id: 'device',
                title: 'Device',
                type: 'basic',
                icon: 'heroicons_outline:device-mobile',
                link: 'devices',
            },
            {
                id: 'eventalert',
                title: 'Event Alert',
                type: 'basic',
                icon: 'heroicons_outline:bell',
                link: 'eventalert',
            },
            {
                id: 'users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'users',
            }
        ],
    },
    {
        id: 'spacer',
        type: 'spacer'
    },
    {
        id: 'doct',
        title: 'Documentation',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        externalLink: true,
        link: 'sign-out'
    },
    {
        id: 'signout',
        title: 'Sign Out',
        type: 'group',
        children: [
            {
                id: 'signout',
                title: 'Sign Out',
                type: 'basic',
                icon: 'heroicons_outline:arrow-left-on-rectangle',
                link: 'sign-out'
            },
        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'stroomer',
        title: 'Stroomer',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:computer-desktop',
                link: 'overview',
            },
            {
                id: 'device',
                title: 'Device',
                type: 'basic',
                icon: 'heroicons_outline:device-mobile',
                link: 'devices',
            },
            {
                id: 'eventalert',
                title: 'Event Alert',
                type: 'basic',
                icon: 'heroicons_outline:bell',
                link: 'eventalert',
            },
            {
                id: 'users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'users',
            }
        ],
    },
    {
        id: 'spacer',
        type: 'spacer'
    },
    {
        id: 'doct',
        title: 'Documentation',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        externalLink: true,
        link: 'sign-out'
    },
    {
        id: 'signout',
        title: 'Sign Out',
        type: 'group',
        children: [
            {
                id: 'signout',
                title: 'Sign Out',
                type: 'basic',
                icon: 'heroicons_outline:arrow-left-on-rectangle',
                link: 'sign-out'
            },
        ]
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'stroomer',
        title: 'Stroomer',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:computer-desktop',
                link: 'overview',
            },
            {
                id: 'device',
                title: 'Device',
                type: 'basic',
                icon: 'heroicons_outline:device-mobile',
                link: 'devices',
            },
            {
                id: 'eventalert',
                title: 'Event Alert',
                type: 'basic',
                icon: 'heroicons_outline:bell',
                link: 'eventalert',
            },
            {
                id: 'users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'users',
            }
        ],
    },
    {
        id: 'spacer',
        type: 'spacer'
    },
    {
        id: 'doct',
        title: 'Documentation',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        externalLink: true,
        link: 'sign-out'
    },
    {
        id: 'signout',
        title: 'Sign Out',
        type: 'group',
        children: [
            {
                id: 'signout',
                title: 'Sign Out',
                type: 'basic',
                icon: 'heroicons_outline:arrow-left-on-rectangle',
                link: 'sign-out'
            },
        ]
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'stroomer',
        title: 'Stroomer',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:computer-desktop',
                link: 'overview',
            },
            {
                id: 'device',
                title: 'Device',
                type: 'basic',
                icon: 'heroicons_outline:device-mobile',
                link: 'devices',
            },
            {
                id: 'eventalert',
                title: 'Event Alert',
                type: 'basic',
                icon: 'heroicons_outline:bell',
                link: 'eventalert',
            },
            {
                id: 'users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'users',
            }
        ],
    },
    {
        id: 'spacer',
        type: 'spacer'
    },
    {
        id: 'doct',
        title: 'Documentation',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        externalLink: true,
        link: 'sign-out'
    },
    {
        id: 'signout',
        title: 'Sign Out',
        type: 'group',
        children: [
            {
                id: 'signout',
                title: 'Sign Out',
                type: 'basic',
                icon: 'heroicons_outline:arrow-left-on-rectangle',
                link: 'sign-out'
            },
        ]
    },
];
