import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Logger } from '@gci/helpers/logger';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (
    route,
    state
) => {
    const router: Router = inject(Router);
    const host = window.location.host;
    const logger = new Logger('NoAuthGuard');

    if (route.routeConfig?.canActivateChild && !host.startsWith('localhost')) {
        const hostArr = host.split('.');
        const subdomain = hostArr.length > 2 ? hostArr[0] : '';
        //const domain = hostArr.slice(1).join('.');

        const language = route.queryParamMap.get("lang");
        let langParam = '';
        if (language) {
            langParam = `&lang=${language}`;
        }
    
        logger.debug("host", host, "subdomain", subdomain);

        if (subdomain !== environment.consolePageSubdomain) {
            if (subdomain=='' || subdomain==environment.landingPageSubdomain) {
                logger.debug("navigate to console", subdomain);
                window.location.href = `${environment.consoleDashboardUrl}?${langParam}`;
            } else {
                logger.log("navigate to console unknown subdomain");
                window.location.href = `${environment.consoleDashboardUrl}?${langParam}`;
            }
            return false;
        }
    }

    // Check the authentication status
    return inject(AuthService)
        .check()
        .pipe(
            switchMap((authenticated) => {
                const language = route.queryParamMap.get("lang");
                let langParam = '';
                if (language) {
                    langParam = `&lang=${language}`;
                }

                logger.debug("langParam", langParam, state.url);

                // If the user is authenticated...
                if (authenticated) {
                    const urlTree = router.parseUrl(`?${langParam}`);
                    logger.debug("state.url", state.url, "urlTree", urlTree);

                    return of(urlTree);
                }

                // Allow the access
                return of(true);
            })
        );
};
