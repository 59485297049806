import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Logger } from '@gci/helpers/logger';
import { environment } from 'environments/environment';
import { switchMap, of } from 'rxjs';
import { AuthService } from '../auth.service';

export const SuperadminAuthGuard: CanActivateFn | CanActivateChildFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService); // Inject AuthService for accessing currentUser
    const host = window.location.host;
    const logger = new Logger('AuthGuard');

    const language = route.queryParamMap.get("lang");
    let langParam = '';
    if (language) {
        langParam = `&lang=${language}`;
    }

    if (route.routeConfig?.canActivateChild && !host.startsWith('localhost')) {
        const hostArr = host.split('.');
        const subdomain = hostArr.length > 2 ? hostArr[0] : '';
        //const domain = hostArr.slice(1).join('.');

        console.log("host", host, "subdomain", subdomain);

        if (subdomain !== environment.consolePageSubdomain) {
            if (subdomain == '' || subdomain == environment.landingPageSubdomain) {
                logger.debug("navigate to console", subdomain);
                window.location.href = environment.consoleDashboardUrl;
                window.location.href = `${environment.consoleDashboardUrl}?${langParam}`;
            } else {
                logger.log("navigate to console unknown subdomain");
                window.location.href = `${environment.consoleDashboardUrl}?${langParam}`;
            }
            return false;
        }
    }

    // Check the authentication status
    return authService
        .check()
        .pipe(
            switchMap((authenticated) => {

                // If the user is not authenticated...
                if (!authenticated) {
                    // Redirect to the sign-in page with a redirectUrl param
                    const redirectURL =
                        state.url === '/sign-out'
                            ? ''
                            : `redirectURL=${state.url}`;

                    const urlTree = router.parseUrl(`sign-in?${redirectURL}${langParam}`);
                    logger.log("state.url", state.url, "urlTree", urlTree);

                    return of(urlTree);
                }

                // Check if the user is a superadmin
                if (authService?.currentUser?.tenant_code_access !== environment.idSuperAdmin) {
                    router.navigate(['signed-in-redirect'])
                    return of(false);
                }

                // Allow the access
                return of(true);
            })
        );
};