import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { BaseComponent } from '@gci/components/base-page/base-component';
import { Logger } from '@gci/helpers/logger';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { forkJoin, Subject, take, takeUntil } from 'rxjs';
import { SwPush } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { PushNotificationService } from 'app/web-api/common/push-notification.service';
import { ToastService } from '@gci/components/toast/toast.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        NgClass,
        MatDividerModule,
        MatTooltipModule,
        CommonModule,
        RouterModule,
        TranslocoModule
    ],
})
export class UserComponent extends BaseComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user!: User;
    isAuthenticated: boolean = false;
    public isNotificationActive: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        public swPush: SwPush,
        private _userService: UserService,
        private _pushService: PushNotificationService,
        private _authService: AuthService,
        private _toastService: ToastService,
        private _translocoService: TranslocoService
    ) {
        super('UserComponent', _translocoService);
        this.swPush.notificationClicks.subscribe(({ action, notification }) => {
            console.log('action', action, 'notification', notification);
        });

        this.swPush.messages.subscribe((payload) => {
            console.log('message payload', payload);
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    override ngOnInit(): void {
        this.activeLanguage = this._translocoService.getActiveLang();

        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                // Notifikasi
                if (this.swPush.isEnabled) {
                    if (user.subscription) {
                        this.isNotificationActive = true;
                    } else {
                        this.isNotificationActive = false;
                    }
                }
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to authentication changes
        this._authService.authenticatedSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe({
                next: (val) => {
                    this.logger.debug("authenticationSubject changes", val);
                    this.isAuthenticated = val;

                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                }
            });

        super.ngOnInit();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }
        this.logger.log('User Component', this.user)
        // Update the user
        this._userService
            .update({
                ...this.user,
                status,
            })
            .pipe(take(1))
            .subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Notification
    // -----------------------------------------------------------------------------------------------------

    enableNotifications(): void {
        this.swPush.requestSubscription({
            serverPublicKey: environment.vapidPublicKey
        })
            .then(sub => {
                console.log('sub', sub.toJSON());
                // forkJoin([
                this._pushService.registerSubscription(sub, { user_id: this.user.id }).subscribe(
                    res => {
                        this._userService.update({ ...this.user, subscription: res.id }).subscribe()
                        this._toastService.success("Success Subscribe Notification")
                    }, err => {
                        this._toastService.error("Error Subscribe Notification")
                    }
                )

                // ])
                // this.toastr.success('Subscribed successfully', 'Push Notification');
            })
            .catch(err => {
                this._toastService.error("Error Subscribe Notification")
                console.error("Could not subscribe to notifications", err);
                // this.toastr.error('Register error', 'Push Notification');
            });
    }

    disableNotifications(): void {
        this.swPush.unsubscribe().then(() => {
            // forkJoin([
            this.user.subscription && this._pushService.deleteSubscribtion(this.user.subscription).subscribe(
                res => {
                    this._userService.update({...this.user, subscription: null})
                    this._toastService.success("Success Unubscribe Notification")
                }
            )
            //     this._userService.update({...this.user, subscription: false})
            // ])
        })
            .catch((err) => {
                this._toastService.success("Error Unubscribe Notification")
                console.error("Error unsubscribe notification", err);
                // this.toastr.error('Unsubscribe error', 'Push Notification');
            })

    }
}

