import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { IToast, StyleToastColor, StyleToastLoadingBarColor, StyleToastPosition, ToastPosition, ToastType } from "./toast.types";
import { ToastService } from "./toast.service";
import { fuseAnimations } from "@fuse/animations";
import { Logger } from "@gci/helpers/logger";

@Component({
    selector: "app-toast",
    templateUrl: "./toast.component.html",
    styleUrls: ["./toast.component.scss"],
    animations: fuseAnimations
})
export class ToastComponent implements OnInit {

    toastInfo: IToast[] = []
    @Input() public position: ToastPosition = 'mr';
    private readonly _logger = new Logger('ToastComponent');

    constructor(
        private _toastService :ToastService,
        private _chaheDetctorRef: ChangeDetectorRef
    ) {}

    ngOnInit(){
        this._toastService.toastInfo$.subscribe(res => {
            this.toastInfo = res;
            this.toastInfo.forEach(toast => this.startCountdown(toast)); // Mulai countdown untuk setiap toast baru
            this._logger.debug(this.toastInfo)
            this._chaheDetctorRef.markForCheck();
        })
    }

    getPosition(): string {
        return StyleToastPosition[this.position];
    }

    getColor(type: ToastType): string {

        return StyleToastColor[type];
    }

    getColorLoadingBar(type: ToastType) {
        return StyleToastLoadingBarColor[type];
    }

    cancelToast(info:IToast) {
        let toastList = this.toastInfo.filter(item => item.id !== info.id);
        this._toastService.toastInfo = toastList;
    }
    startCountdown(toast: IToast) {
        
        if (!toast.intervalId) {
            toast.intervalId = setInterval(() => {
                if (!toast.onHover) {
                    toast.activeTime -= 50; // Kurangi activeTime hanya jika tidak di-hover
                }

                if (toast.activeTime <= 0) {
                    toast.active = false; // Jika activeTime habis, aktifkan false
                    this.cancelToast(toast);
                    clearInterval(toast.intervalId); // Hentikan interval
                }

                this._chaheDetctorRef.markForCheck();
            }, 50); // Hitung mundur per detik
        }
    }

    onHover(toast: IToast) {
        toast.onHover = true; // Saat di-hover, aktifkan status hover
    }

    onLeave(toast: IToast) {
        toast.onHover = false; // Saat hover dilepas, nonaktifkan status hover
    }

    getProgressWidth(toast: IToast): string {
        const initialTime = 5000; // Misalnya waktu awal adalah 5 detik, bisa disesuaikan
        const percentage = (toast.activeTime / initialTime) * 100;
        return `${percentage}%`;
    }
}
